@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&family=Titillium+Web:wght@300;400;700&display=swap);
body{margin:0;font-family:'Open Sans', 'Roboto', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background:#ffffff}@-webkit-keyframes slide-fwd-center{0%{transform:translateZ(0)}100%{transform:translateZ(160px)}}@keyframes slide-fwd-center{0%{transform:translateZ(0)}100%{transform:translateZ(160px)}}

.hotspot-wrapper .hotspot{visibility:hidden;display:flex;align-items:center;justify-content:center;border:solid 1px transparent}.hotspot-wrapper.active .hotspot{z-index:999;visibility:visible}.hotspot-wrapper.active .highlight{z-index:999}.hotspot-wrapper .popover,.hotspot-wrapper .bs-popover-auto[x-placement^=right]>.arrow::before,.hotspot-wrapper .bs-popover-right>.arrow::before,.hotspot-wrapper .bs-popover-auto[x-placement^=left]>.arrow::before,.hotspot-wrapper .bs-popover-left>.arrow::before,.hotspot-wrapper .bs-popover-auto[x-placement^=top]>.arrow::before,.hotspot-wrapper .bs-popover-top>.arrow::before,.hotspot-wrapper .bs-popover-auto[x-placement^=bottom]>.arrow::before,.hotspot-wrapper .bs-popover-bottom>.arrow::before{border:none !important}.hotspot-wrapper .popover{min-width:245px;max-width:800px}.hotspot-wrapper .popover-content{overflow-y:auto;scrollbar-width:none;-ms-overflow-style:none}.hotspot-wrapper .popover-content::-webkit-scrollbar{background:transparent;width:0px}.hotspot-wrapper .hotspot-description{padding-top:5px}.hotspot-wrapper .hotspot-icon{font-size:28px}.hotspot-wrapper .guide-icon{font-size:40px;font-weight:900}

.mobile-display{background:url(/static/media/mobile.5c57343d.png);background-repeat:no-repeat;background-size:310px 600px;height:600px;width:310px;margin-top:2%}.mobile-display .mobile-content{position:relative;height:500px;overflow:auto}

.flow-item{color:white;border:1px solid;border-radius:5px}.active-flow-item{border:4px solid white}

.flow-selection,.flow-selection-bg{width:100%;height:100%;position:fixed;top:0;left:0}.flow-selection-bg{background:black;opacity:0.75}.flow-selection-content{position:relative;padding:0px 25px 0px 25px;margin-top:5%;color:#ffffff;font-size:20px;text-align:justify}

.demo-title{color:white}.hotspot-layer-wrapper{position:absolute;top:0;width:100%;height:100%}.hidden{visibility:none;z-index:-1;position:fixed;height:0;width:0}

.embed-player{background-color:black}.embed-player video{object-fit:cover}.full-size-embed{width:100%;height:100%}.pop-out-button{background-color:#d6d5d5;border-radius:2px;position:absolute;top:0px;right:0px;font-size:1em;cursor:pointer}.pop-out-button:hover{font-size:1.10em}.image-embed{width:100%;height:100%}

.marvel-iframe-wrapper{position:absolute;width:100%;height:100%}.marvel-iframe{width:100%;height:100%}

.center-container{height:100vh;display:flex;justify-content:center;align-items:center}.center-container .status-code{border-right:solid 2px #000}.center-container .status-code,.center-container .status-message{height:70px}

.demo-title{color:white}.loading-spinner{position:absolute;left:50%;top:50%}

.App-logo{height:40vmin;pointer-events:none}@media (prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear}}.App-header{background-color:#fafafa;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:white}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}@keyframes App-logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}

